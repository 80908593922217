import React, { useRef } from 'react';
import cards from '../../../styles/ui/CardComponents.module.scss';
import { useIntl } from 'react-intl';
import { Bar } from 'react-chartjs-2';
import { ArcElement, ChartOptions } from 'chart.js';
import Chart from 'chart.js/auto';

Chart.register(ArcElement);

interface IEstimatorBarChartCard {
	title: string;
	value1: number;
	value2: number;
	unit: string;
	instructions?: string;
	customCss?: string;
}

export default function EstimatorBarChartCard({
	title,
	value1,
	value2,
	unit,
	instructions,
	customCss,
}: IEstimatorBarChartCard) {
	const intl = useIntl();
	const chartRef = useRef(null);

	const data = {
		labels: [
			intl.formatMessage({
				id: 'component.estimator.annual_profit_vs_profit.savings',
				defaultMessage: 'Savings',
			}),
			intl.formatMessage({
				id: 'component.estimator.annual_profit_vs_profit.profits',
				defaultMessage: 'Profits',
			}),
		],
		datasets: [
			{
				backgroundColor: ['#00000015', '#24ff0030'],
				borderWidth: 2,
				borderColor: ['#00000060', '#24ff00'],
				borderRadius: 10,
				data: [value1, value2],
			},
		],
	};

	const options: ChartOptions<'bar'> = {
		responsive: true,
		plugins: {
			tooltip: {
				enabled: true,
				yAlign: 'bottom',
				callbacks: {
					label: (tooltipItem): string => ` ${tooltipItem.raw} ${unit}`,
				},
			},
			legend: {
				display: false,
				labels: {
					//display: false,
				},
			},
		},
		scales: {
			y: {
				min: 0,
				ticks: {
					//color: "black",
					stepSize: 2000,
					font: {
						family: 'Space Grotesk',
						//size: 12,
						weight: '700',
					},
				},
			},
			x: {
				ticks: {
					font: {
						family: 'Space Grotesk',
						weight: '700',
					},
				},
			},
		},
	};

	return (
		<div
			className={`${cards.estimatorCard} ${customCss} card d-flex justify-content-center align-items-center text-center`}
		>
			<h2>{title}</h2>

			<div className={cards.barContainer}>
				<Bar
					data={data}
					options={options}
					width={100}
					height={100}
					ref={chartRef}
				></Bar>
			</div>
			<div className={cards.instructions}>{instructions}</div>
		</div>
	);
}
