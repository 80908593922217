import React, { useState, useRef, useEffect } from 'react';
import estimators from 'styles/Estimator.module.scss';
import cards from 'styles/ui/CardComponents.module.scss';
import grids from 'styles/ui/Grid.module.scss';

import { FormattedMessage, useIntl } from 'react-intl';
import EstimatorSliderCard from '../../ui/estimator/estimatorSliderCard';
import EstimatorTextCard from '../../ui/estimator/estimatorTextCard';
import EstimatorDoughnutChartCard from '../../ui/estimator/estimatorDoughnutChartCard';
import EstimatorBarChartCard from '../../ui/estimator/estimatorBarChartCard';
import { roundWithPrecision, safeNumber } from 'helpers/numberHelpers';
import { ENV_VARS } from 'helpers/constants';
import logger from 'helpers/logging';
import { max } from 'date-fns/esm';
import useBootstrapCollapse from 'customHooks/useCollapse';
import { interpolatePvSystemPriceByPower } from 'helpers/estimatorHelpers';
import CarouselSlider from 'components/animation/carouselSlider';

import WriteToUsBtn from '../modules/WriteToUsBtn';
//import { Collapse } from 'bootstrap';

interface IEstimatorStepTwo {
	isStepOneComplete: boolean;
	userAvgMonthlyConsumptionInKwh: number | null;
	power: number;
	updateRecommendedSystemPower(value: number): void;
}

export default function EstimatorStepTwo({
	isStepOneComplete,
	userAvgMonthlyConsumptionInKwh,
	power,
	updateRecommendedSystemPower,
}: IEstimatorStepTwo) {
	const intl = useIntl();
	const estimatorAccordionTrigger = useRef(null);

	const presetPower = (consumption: number | null) => {
		let recommendedPower = 5;
		if (consumption === null) return recommendedPower;
		const calculatedPower =
			(consumption / 30.0 / ENV_VARS.INSOLATION_HOURS_PER_DAY) *
			(consumption < 255 ? 1 : 1.3);
		if (calculatedPower < ENV_VARS.RECOMMENDED_POWER_MIN) {
			recommendedPower = ENV_VARS.RECOMMENDED_POWER_MIN;
		} else if (calculatedPower > ENV_VARS.RECOMMENDED_POWER_MAX) {
			recommendedPower = ENV_VARS.RECOMMENDED_POWER_MAX;
		} else {
			recommendedPower = calculatedPower;
		}
		return roundWithPrecision(
			recommendedPower,
			ENV_VARS.RECOMMENDED_POWER_PRECISION,
			true
		);
	};

	const [recommendedSystemPower, setRecommendedSystemPower] = useState(
		power ?? 5
	);

	const [billReductionPercentage, setBillReductionPercentage] = useState(0);
	const [monthlyProductionInKwh, setMonthlyProductionInKwh] = useState(0);
	const [annualProductionInKwh, setAnnualProductionInKwh] = useState(0);
	const [forcastedMonthlySavings, setForcastedMonthlySavings] = useState(0);
	const [forcastedAnnualSavings, setForcastedAnnualSavings] = useState(0);
	const [maximalAnnualSavings, setMaximalAnnualSavings] = useState(0);
	const [forcastedLifeTimeSavings, setForcastedLifeTimeSavings] = useState(0);
	const [maximalLifeTimeSavings, setMaximalLifeTimeSavings] = useState(0);
	const [totalPvSystemCost, setTotalPvSystemCost] = useState(0);
	const [investmentRecoupTime, setInvestmentRecoupTime] = useState(0);
	const [prosumerAnnualProfit, setProsumerAnnualProfit] = useState(0);

	// when the user changes either the bill cost or the monthly consumption,
	// update the recommended power of the PV system
	useEffect(() => {
		const recommendedPower = presetPower(userAvgMonthlyConsumptionInKwh);
		setRecommendedSystemPower(recommendedPower);
		updateRecommendedSystemPower(recommendedPower);
	}, [userAvgMonthlyConsumptionInKwh]);

	useEffect(() => {
		setRecommendedSystemPower(power);
	}, [power]);

	useEffect(() => {
		const userConsumptionInKwh = userAvgMonthlyConsumptionInKwh ?? 0;
		const pricePerKwh =
			userConsumptionInKwh < ENV_VARS.PRICE_PER_KWH_GOV_CAP
				? ENV_VARS.MIN_PRICE_PER_KWH
				: ENV_VARS.PRICE_PER_KWH;

		const userMonthlyCost = userConsumptionInKwh * pricePerKwh;

		const totalPvSystemCostLocal = Math.round(
			interpolatePvSystemPriceByPower(
				recommendedSystemPower,
				ENV_VARS.PV_SYSTEM_PRICE_PER_KWH_WITH_VAT
			)
		);
		const monthlyProductionInKwhLocal = Math.round(
			recommendedSystemPower * ENV_VARS.INSOLATION_HOURS_PER_DAY * 30
		);
		const annualProductionInKwhLocal = Math.round(
			recommendedSystemPower * ENV_VARS.INSOLATION_HOURS_PER_DAY * 365
		);
		const forcastedMonthlySavingsLocal = Math.round(
			monthlyProductionInKwhLocal * pricePerKwh
		);
		const forcastedAnnualSavingsLocal = Math.round(
			annualProductionInKwhLocal * pricePerKwh
		);

		const forecastedProsumerAnnualProfitLocal = Math.max(
			Math.round(
				(annualProductionInKwhLocal - userConsumptionInKwh * 12) *
					ENV_VARS.INJECTION_PRICE_PER_KWH
			),
			0
		);

		const maximalAnnualSavingsLocal = Math.max(
			Math.round(Math.min(forcastedAnnualSavingsLocal, userMonthlyCost * 12)),
			0
		);

		const prosumerAnnualProfitLocal = Math.round(
			safeNumber(forecastedProsumerAnnualProfitLocal, 0)
		);

		const investmentRecoupTimeLocal = roundWithPrecision(
			totalPvSystemCostLocal /
				Math.min(
					maximalAnnualSavingsLocal + prosumerAnnualProfitLocal,
					userMonthlyCost * 12 + prosumerAnnualProfitLocal
				),
			0.1
		);
		const forcastedLifeTimeSavingsLocal = Math.round(
			forcastedAnnualSavingsLocal * 25
		);
		const maximalLifeTimeSavingsLocal = Math.round(
			maximalAnnualSavingsLocal * 25
		);
		const billReductionPercentageLocal = Math.round(
			(Math.min(forcastedMonthlySavingsLocal, userMonthlyCost) /
				(userMonthlyCost > 0 ? userMonthlyCost : 1)) *
				100
		);

		// console.log('------------------------------------');
		// console.log('userConsumptionInKwh:' + userConsumptionInKwh);
		// console.log('recommendedSystemPower:' + recommendedSystemPower);
		// console.log('billReductionPercentage:' + billReductionPercentageLocal);
		// console.log('monthlyProductionInKwh:' + monthlyProductionInKwhLocal);
		// console.log('annualProductionInKwh:' + annualProductionInKwhLocal);
		// console.log('forcastedMonthlySavings:' + forcastedMonthlySavingsLocal);
		// console.log('forcastedAnnualSavings:' + forcastedAnnualSavingsLocal);
		// console.log('maximalAnnualSavings:' + maximalAnnualSavingsLocal);
		// console.log('prosumerAnnualProfit:' + prosumerAnnualProfitLocal);
		// console.log('forcastedLifeTimeSavings:' + forcastedLifeTimeSavingsLocal);
		// console.log('totalPvSystemCost:' + totalPvSystemCostLocal);
		// console.log('investmentRecoupTime:' + investmentRecoupTimeLocal);
		// console.log('billReductionPercentage:' + billReductionPercentageLocal);

		setTotalPvSystemCost(totalPvSystemCostLocal); // correct
		setMonthlyProductionInKwh(monthlyProductionInKwhLocal); // correct
		setAnnualProductionInKwh(annualProductionInKwhLocal); // correct
		setForcastedMonthlySavings(forcastedMonthlySavingsLocal); // correct
		setForcastedAnnualSavings(forcastedAnnualSavingsLocal); // correct
		setMaximalAnnualSavings(maximalAnnualSavingsLocal); // correct
		setInvestmentRecoupTime(investmentRecoupTimeLocal); // correct
		setProsumerAnnualProfit(prosumerAnnualProfitLocal);
		setForcastedLifeTimeSavings(forcastedLifeTimeSavingsLocal);
		setMaximalLifeTimeSavings(maximalLifeTimeSavingsLocal);
		setBillReductionPercentage(billReductionPercentageLocal);
	}, [recommendedSystemPower, userAvgMonthlyConsumptionInKwh]);

	return (
		<div
			className={`${estimators.estimatorStepTwo} col-12`}
			id='estimatorStepTwo'
		>
			<button
				ref={estimatorAccordionTrigger}
				className='d-none'
				type='button'
				data-bs-toggle='collapse'
				data-bs-target='#estimatorConfigurator'
				aria-expanded='true'
				aria-controls='estimatorConfigurator'
			></button>
			<div
				id='estimatorConfigurator'
				className={`${
					isStepOneComplete || true ? 'show' : ''
				} accordion-collapse collapse mt-5`}
				data-bs-parent='#estimatorStepTwo'
			>
				<div className={`${estimators.estimation} ${grids.estimatorGrid}`}>
					<EstimatorSliderCard
						title={intl.formatMessage({
							id: 'component.estimator.recommended_system_power.title',
							defaultMessage: 'Recommended system power',
						})}
						value={recommendedSystemPower}
						unit={'kW'}
						step={0.5}
						instructions={intl.formatMessage({
							id: 'component.estimator.recommended_system_power.instructions',
							defaultMessage: 'Modify the recommended power',
						})}
						updateRecommendedSystemPower={updateRecommendedSystemPower}
						customCss={grids.gridItem}
					></EstimatorSliderCard>
					<EstimatorTextCard
						title={intl.formatMessage({
							id: 'component.estimator.annual_savings.title',
							defaultMessage: 'Annual savings',
						})}
						value={maximalAnnualSavings}
						unit={'RON'}
						instructions={intl.formatMessage({
							id: 'component.estimator.annual_savings.instructions',
							defaultMessage: 'Money saved by installing a photovoltaic system',
						})}
						customCss={grids.gridItem}
					></EstimatorTextCard>
					<EstimatorTextCard
						title={intl.formatMessage({
							id: 'component.estimator.25_years_saving.title',
							defaultMessage: '25 years savings',
						})}
						value={maximalLifeTimeSavings}
						unit={'RON'}
						instructions={intl.formatMessage({
							id: 'component.estimator.annual_savings.instructions',
							defaultMessage: 'Money saved by installing a photovoltaic system',
						})}
						customCss={grids.gridItem}
					></EstimatorTextCard>
					<EstimatorDoughnutChartCard
						title={intl.formatMessage({
							id: 'component.estimator.bill_reduction.title',
							defaultMessage: 'Bill reduction (%)',
						})}
						value={billReductionPercentage}
						unit={'%'}
						instructions={intl.formatMessage({
							id: 'component.estimator.bill_reduction.instructions',
							defaultMessage: 'From your average bill',
						})}
						customCss={grids.gridItem}
					></EstimatorDoughnutChartCard>
					{ENV_VARS.SHOW_PV_COST_CARD && (
						<div
							className={`${grids.gridItem} d-flex align-items-center justify-content-center position-relative mb-5`}
						>
							<EstimatorTextCard
								title={intl.formatMessage({
									id: 'component.estimator.pv_cost.title',
									defaultMessage: 'Photovoltaic system total cost',
								})}
								value={totalPvSystemCost * 0.85}
								subValue={roundWithPrecision(
									(totalPvSystemCost * 0.85) / 5,
									0.1
								)}
								unit={'RON'}
								instructions={intl.formatMessage({
									id: 'component.estimator.pv_cost.instructions',
									defaultMessage: 'This is an approximation',
								})}
								customCss={`${cards.roundCard}`}
							></EstimatorTextCard>
							<WriteToUsBtn
								hasPower={isStepOneComplete}
								power={recommendedSystemPower}
							></WriteToUsBtn>
						</div>
					)}
					{ENV_VARS.SHOW_ANNUAL_PROFIT_CARD && (
						<EstimatorTextCard
							title={intl.formatMessage({
								id: 'component.estimator.annual_profit.title',
								defaultMessage: 'Annual profit',
							})}
							value={prosumerAnnualProfit}
							unit={'RON'}
							instructions={intl.formatMessage({
								id: 'component.estimator.annual_profit.instructions',
								defaultMessage: 'Assuming prosumer status',
							})}
							customCss={grids.gridItem}
						></EstimatorTextCard>
					)}
					<EstimatorTextCard
						title={intl.formatMessage({
							id: 'component.estimator.annual_production.title',
							defaultMessage: 'Estimated annual production',
						})}
						value={annualProductionInKwh}
						unit={'kWh'}
						instructions={intl.formatMessage({
							id: 'component.estimator.annual_production.instructions',
							defaultMessage:
								'Media este influențată de locație, componentele folosite, orientare, înclinare, variația cantității de lumină, grad de murdărire, etc',
						})}
						customCss={grids.gridItem}
					></EstimatorTextCard>
					<EstimatorBarChartCard
						title={intl.formatMessage({
							id: 'component.estimator.annual_profit_vs_profit.title',
							defaultMessage: 'Savings vs Provit',
						})}
						value1={maximalAnnualSavings}
						value2={prosumerAnnualProfit}
						unit={'RON'}
						instructions={intl.formatMessage({
							id: 'component.estimator.annual_profit_vs_profit.instructions',
							defaultMessage: 'Assuming prosumer status',
						})}
						customCss={grids.gridItem}
					></EstimatorBarChartCard>
					<EstimatorTextCard
						title={intl.formatMessage({
							id: 'component.estimator.investment_recovery_time.title',
							defaultMessage: 'Investment recovery time',
						})}
						value={investmentRecoupTime}
						unit={intl.formatMessage({
							id: 'component.estimator.years',
							defaultMessage: 'years',
						})}
						instructions={intl.formatMessage({
							id: 'component.estimator.investment_recovery_time.instructions',
							defaultMessage:
								'How long does it take to recoup the initial cost of the photovoltaic system',
						})}
						customCss={grids.gridItem}
					></EstimatorTextCard>
				</div>
			</div>
		</div>
	);
}
