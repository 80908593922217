import React, { useRef } from 'react';
import cards from '../../../styles/ui/CardComponents.module.scss';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, ChartOptions } from 'chart.js';
import Chart from 'chart.js/auto';
import { safeNumber, roundWithPrecision } from '../../../helpers/numberHelpers';
Chart.register(ArcElement);

interface IEstimatorDoughnutChartCard {
	title: string;
	value: number;
	unit: string;
	instructions?: string;
	customCss?: string;
}

export default function EstimatorDoughnutChartCard({
	title,
	value,
	unit,
	instructions,
	customCss,
}: IEstimatorDoughnutChartCard) {
	const chartRef = useRef(null);

	const data = {
		datasets: [
			{
				data: [
					roundWithPrecision(safeNumber(value, 0, 100), 1),
					100 - roundWithPrecision(safeNumber(value, 0, 100), 1),
				],
				backgroundColor: ['#24ff00', '#000000'],
				hoverOffset: 0,
			},
		],
	};

	const options: ChartOptions<'doughnut'> = {
		elements: {
			arc: {
				//weight: 0.5,
				borderWidth: 0,
			},
		},
		cutout: '65%',
		plugins: {
			tooltip: {
				enabled: false,
			},
		},
		hover: { mode: 'nearest' },
		// legend: {
		// 	display: false,
		// },
	};

	return (
		<div
			className={`${cards.estimatorCard} ${customCss} card d-flex justify-content-center align-items-center text-center`}
		>
			<h2>{title}</h2>

			<div className={cards.doughnutContainer}>
				<Doughnut
					data={data}
					options={options}
					width={100}
					height={100}
					ref={chartRef}
				></Doughnut>
				<div className={`${cards.valueContainer} ${cards.doughnutValue}`}>
					<div>
						<span className={cards.value}>
							{roundWithPrecision(safeNumber(value, 0, 100), 1)}
						</span>
						<span className={cards.unit}> {unit}</span>
					</div>
				</div>
			</div>
			<div className={cards.instructions}>{instructions}</div>
		</div>
	);
}
