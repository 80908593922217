export const interpolatePvSystemPriceByPower = (
	userPower: number,
	priceData: any
): number => {
	const keys = Object.keys(priceData)
		.map(Number)
		.sort((a, b) => a - b);
	for (let i = 0; i < keys.length; i++) {
		if (userPower === keys[i]) {
			return priceData[keys[i]];
		} else if (userPower < keys[i]) {
			if (i === 0) {
				return priceData[keys[0]];
			}
			const lowerKey = keys[i - 1];
			const upperKey = keys[i];
			const lowerPrice = priceData[lowerKey];
			const upperPrice = priceData[upperKey];
			const priceDifference = upperPrice - lowerPrice;
			const powerDifference = upperKey - lowerKey;
			const proportion = (userPower - lowerKey) / powerDifference;
			return lowerPrice + priceDifference * proportion;
		}
	}
	return priceData[keys[keys.length - 1]];
};
