import React, { useEffect, useRef, useState } from "react";
import cards from "styles/ui/CardComponents.module.scss";
import sliders from "styles/ui/Slider.module.scss";
import { ENV_VARS } from "helpers/constants";
//@ts-ignore
import RangeSlider from "react-range-slider-input";
import "react-range-slider-input/dist/style.css";
import {
	countDecimals,
	logScaleTransform,
	reverseLogScaleTransform,
} from "helpers/helpers";

interface IEstimatorExpoSliderComponent {
	value?: number;
	unit: string;
	min: number;
	max: number;
	precision: number;
	step?: number;
	updateValue(value: number): void;
}

export default function EstimatorExpoSliderComponent({
	value,
	unit,
	min,
	max,
	precision,
	step,
	updateValue,
}: IEstimatorExpoSliderComponent) {
	const sliderRef = useRef(null);
	const [val, setVal] = useState(value);

	const scale = logScaleTransform(min, max, step);
	const reverseScale = reverseLogScaleTransform(min, max, step);

	const sliderValChange = (sliderValue: number) => {
		const transformedValue = reverseScale(sliderValue);
		setVal(transformedValue);
		updateValue(transformedValue);
	};

	useEffect(() => {
		setVal(value);
	}, [value]);

	return (
		<div className={`${cards.slider}`}>
			<div className={`${cards.valueContainer}`}>
				<div>
					<span className={cards.value}>
						{val?.toFixed(countDecimals(precision))}
					</span>
					<span className={cards.unit}> {unit}</span>
				</div>
			</div>
			<div className={sliders.wrapper}>
				<RangeSlider
					ref={sliderRef}
					step={precision}
					min={min}
					max={max}
					value={[min, scale(value ?? min)]}
					rangeSlideDisabled={true}
					thumbsDisabled={[true, false]}
					onInput={(value: number[]) => {
						sliderValChange(value[1]);
					}}
				/>
			</div>
			<div className={cards.sliderLimits}>
				<span>
					{min} {unit}
				</span>
				<span>
					{max} {unit}
				</span>
			</div>
		</div>
	);
}
