import React, { useState, useEffect } from 'react';
import estimators from 'styles/Estimator.module.scss';
import buttons from 'styles/_buttons.module.scss';
import cards from 'styles/ui/CardComponents.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import EstimatorStepOne from 'components/ui/estimator/estimatorStepOne';
import EstimatorStepTwo from 'components/ui/estimator/estimatorStepTwo';
import Link from 'next/link';
import logger from 'helpers/logging';
import { IAdsTracking } from 'data/abstractions/landingInterfaces';
import { ENV_VARS } from 'helpers/constants';
import Image from 'next/image';
import CtaButton from 'components/ui/ctaButton';
import SecondaryCtaButton from 'components/ui/secondaryCtaButton';

interface IEstimator {
	isFromAdds?: boolean;
	isFromGoogle?: boolean;
	customCss?: string;
	customQueryParams?: IAdsTracking;
	updatePower?: (value: number) => void;
}

export default function Estimator({
	isFromGoogle = false,
	isFromAdds = false,
	customCss = undefined,
	customQueryParams = undefined,
	updatePower,
}: IEstimator) {
	const intl = useIntl();

	const [isStepOneComplete, setIsStepOneComplete] = useState(true);
	const [userAvgMonthlyConsumptionInKwh, setUserAvgMonthlyConsumptionInKwh] =
		useState<number | null>(null);
	const [recommendedSystemPower, setRecommendedSystemPower] = useState(5);

	const updateUserAvgMonthlyConsumptionInKwh = (value: number | null) => {
		setUserAvgMonthlyConsumptionInKwh(value);
	};

	// when the user set the recommended power of the PV system via the slider,
	// store the new value for recalculating every estimation card
	const updateRecommendedSystemPower = (value: number) => {
		logger.debug('updated recommended_system_power from slider: ' + value);
		setRecommendedSystemPower(value);
		if (updatePower) {
			updatePower(value);
		}
	};
	const updateLogarithmRecommendedSystemPower = (value: number) => {
		logger.debug('updated logarithm value from slider: ' + value);
		setRecommendedSystemPower(value);
	};

	// const handleClick = (e: Event) => {
	// 	//e.preventDefault();
	// 	if (isFromAdds) {
	// 		if (typeof window !== 'undefined' && window.fbq) {
	// 			window.fbq('track', 'dummyEvent', {
	// 				// your data here
	// 				test_event_code: 'TEST33935',
	// 			});
	// 			console.log('dummyEvent');
	// 		}
	// 	}
	// };

	useEffect(() => {
		setIsStepOneComplete(userAvgMonthlyConsumptionInKwh !== null);
	}, [userAvgMonthlyConsumptionInKwh]);

	return (
		<section className={`${estimators.estimator} ${customCss}`} id='estimator'>
			<div className='container'>
				<div
					className={`${estimators.card} ${cards.cardElevation} border-0 mt-5 mb-5`}
				>
					<div className='row'>
						<div className='col-lg-12 col-12'>
							<h2 className={`${estimators.heading} h1`}>
								<FormattedMessage
									id='component.estimator.title'
									defaultMessage='Solar investment estimator'
								/>
							</h2>
							<h3 className={`${estimators.content} mb-4 mb-lg-0`}>
								<FormattedMessage
									id='component.estimator.content'
									defaultMessage='estimator content'
								/>
							</h3>
						</div>
						<EstimatorStepOne
							updateUserAvgMonthlyConsumptionInKwh={
								updateUserAvgMonthlyConsumptionInKwh
							}
						></EstimatorStepOne>
						<EstimatorStepTwo
							isStepOneComplete={isStepOneComplete}
							power={recommendedSystemPower}
							userAvgMonthlyConsumptionInKwh={userAvgMonthlyConsumptionInKwh}
							updateRecommendedSystemPower={updateRecommendedSystemPower}
						></EstimatorStepTwo>
					</div>
					{/* <Link
						href={{
							pathname: '/system-configurator',
							query: {
								power: recommendedSystemPower,
								consumption: userAvgMonthlyConsumptionInKwh,
								utm_source: customQueryParams?.utm_source,
								utm_medium: customQueryParams?.utm_medium,
								utm_campaign: customQueryParams?.utm_campaign,
							},
						}}
						className={`${
							estimators.btnPhoton
						} ${'btn btn-primary btn-photon text-nowrap'}`}
						//onClick={handleClick}
					>
						<FormattedMessage
							id='component.nav.get_quote'
							defaultMessage='Get quote'
						/>
					</Link> */}

					{!isFromGoogle && (
						<Link
							aria-label='Chat on WhatsApp'
							href={`https://wa.me/${ENV_VARS.ALTREAL_PHONE_2.replace(
								'+',
								''
							).replace(/\s+/g, '')}?text=${`${intl.formatMessage({
								id: `component.messages.whatsapp.lead_first_message${
									isStepOneComplete ? '_with_power' : ''
								}`,
							})}${isStepOneComplete ? ` ${recommendedSystemPower}kW` : ''}`}`}
							className={`${buttons.btnWhatsapp} ${'btn'}`}
							target='_blank'
						>
							<span className={buttons.desktop}>
								<FormattedMessage
									id='component.nav.contact_us_via_whatsapp'
									defaultMessage='Write to us on Whatsapp'
								/>
							</span>
							<span className={buttons.mobile}>
								<FormattedMessage
									id='component.nav.contact_us'
									defaultMessage='Write to us'
								/>
							</span>
							<Image
								src='/icons/icon-whatsapp.png'
								alt='Chat on WhatsApp'
								fill
								className='ms-3'
							/>
						</Link>
					)}

					{isFromGoogle && (
						<div
							className={
								'd-flex justify-content-center gap-5 position-relative'
							}
						>
							<CtaButton
								label={intl.formatMessage({
									id: 'component.lead.hero.label',
									defaultMessage: 'Get quote',
								})}
								href={'#register-lead'}
							></CtaButton>

							{/* <SecondaryCtaButton
								label={intl.formatMessage({
									id: 'component.nav.contact_us',
									defaultMessage: 'Write to us',
								})}
								href={`https://wa.me/${ENV_VARS.ALTREAL_PHONE_2.replace(
									'+',
									''
								).replace(/\s+/g, '')}?text=${`${intl.formatMessage({
									id: `component.messages.whatsapp.google_lead_first_message${
										recommendedSystemPower && recommendedSystemPower > 0
											? '_with_power'
											: ''
									}`,
								})}${
									recommendedSystemPower && recommendedSystemPower > 0
										? ` ${recommendedSystemPower}kW`
										: ''
								}`}`}
							></SecondaryCtaButton> */}
						</div>
					)}
				</div>
			</div>
		</section>
	);
}
