import React, { useEffect, useState } from 'react';
import cards from 'styles/ui/CardComponents.module.scss';
import Dinero from 'dinero.js';

interface IEstimatorTextCard {
	title: string;
	value: number;
	subValue?: number | undefined;
	unit: string;
	instructions?: string;
	customCss?: string;
}

export default function EstimatorTextCard({
	title,
	value,
	subValue,
	unit,
	instructions,
	customCss,
}: IEstimatorTextCard) {
	const [val, setVal] = useState(value);
	useEffect(() => {
		setVal(value);
	}, [value, subValue]);
	return (
		<div
			className={`${cards.estimatorCard} ${customCss} card d-flex justify-content-center align-items-center text-center`}
		>
			<h2>{title}</h2>
			<div className={cards.valueContainer}>
				<div>
					<span className={cards.value}>
						{Number.isInteger(val) &&
							Dinero({
								amount: val * 100,
							}).toFormat('0,0.0')}
						{Number.isInteger(val) === false &&
							Dinero({
								amount: Math.ceil(val * 100),
							}).toFormat('0,0.0')}
					</span>
					<span className={cards.unit}> {unit}</span>
				</div>
				{subValue && (
					<div className={cards.subValueContainer}>
						<span className={cards.value}>{subValue}</span>
						<span className={cards.unit}> EURO</span>
					</div>
				)}
			</div>
			<div className={cards.instructions}>{instructions}</div>
		</div>
	);
}
