import React from 'react';
import Image from 'next/image';
import buttons from 'styles/_buttons.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';

import 'react-lite-youtube-embed/dist/LiteYouTubeEmbed.css';
import { ENV_VARS } from 'helpers/constants';
import Link from 'next/link';
interface IWriteToUs {
	hasPower: boolean;
	power?: number;
}

export default function WriteToUsBtn({ hasPower, power }: IWriteToUs) {
	const intl = useIntl();

	return (
		<Link
			aria-label='Chat on WhatsApp'
			href={`https://wa.me/${ENV_VARS.ALTREAL_PHONE_2.replace('+', '').replace(
				/\s+/g,
				''
			)}?text=${`${intl.formatMessage({
				id: `component.messages.whatsapp.lead_first_message${
					hasPower ? '_with_power' : ''
				}`,
			})}${hasPower ? ` ${power}kW` : ''}`}`}
			className={`${buttons.btnWhatsapp} ${'btn'}`}
			style={{ right: 'unset' }}
			target='_blank'
		>
			<span>
				<FormattedMessage
					id='component.nav.contact_us'
					defaultMessage='Write to us'
				/>
			</span>
			<Image
				src='/icons/icon-whatsapp.png'
				alt='Chat on WhatsApp'
				fill
				className='ms-3'
			/>
		</Link>
	);
}
