import { ENV_VARS } from 'helpers/constants';
import React from 'react';
import cards from '../../../styles/ui/CardComponents.module.scss';
import EstimatorExpoSliderComponent from './estimatorExpoSliderComponent';

interface IEstimatorSliderCard {
	title: string;
	value?: number;
	unit: string;
	instructions?: string;
	step?: number;
	updateRecommendedSystemPower(value: number): void;
	customCss?: string;
}

export default function EstimatorSliderCard({
	title,
	value,
	unit,
	instructions,
	step,
	updateRecommendedSystemPower,
	customCss,
}: IEstimatorSliderCard) {
	return (
		<div
			className={`${cards.estimatorCard} ${customCss} card d-flex justify-content-center align-items-center text-center`}
		>
			<h2>{title}</h2>
			<div className={cards.sliderContainer}>
				<EstimatorExpoSliderComponent
					value={value}
					unit={unit}
					min={ENV_VARS.RECOMMENDED_POWER_MIN}
					max={ENV_VARS.RECOMMENDED_POWER_MAX}
					precision={ENV_VARS.RECOMMENDED_POWER_PRECISION}
					step={step ?? ENV_VARS.RECOMMENDED_POWER_STEP}
					updateValue={updateRecommendedSystemPower}
				></EstimatorExpoSliderComponent>
			</div>
			{instructions && <div className={cards.instructions}>{instructions}</div>}
		</div>
	);
}
