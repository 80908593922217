import React, { useState, useRef, useEffect, ChangeEvent } from 'react';
import estimators from 'styles/Estimator.module.scss';
import inputs from 'styles/_inputs.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { ENV_VARS } from 'helpers/constants';

interface IEstimatorStepOne {
	updateUserAvgMonthlyConsumptionInKwh(value: number | null): void;
}
export default function EstimatorStepOne({
	updateUserAvgMonthlyConsumptionInKwh,
}: IEstimatorStepOne) {
	const intl = useIntl();
	const [averageBillCost, setAverageBillCost] = useState<number | null>(null);
	const [averageMonthlyKwh, setAverageMonthlyKwh] = useState<number | null>(
		null
	);

	const handleAverageBillCost = (e: ChangeEvent<HTMLInputElement>) => {
		const val = e.target?.value;
		if (val?.length > 0) {
			setAverageBillCost(parseFloat(val));
			setAverageMonthlyKwh(null);
		} else {
			setAverageBillCost(null);
		}
	};

	const handleAverageMonthlyKwhChange = (e: ChangeEvent<HTMLInputElement>) => {
		const val = e.target?.value;
		if (val?.length > 0) {
			setAverageMonthlyKwh(parseFloat(val));
			setAverageBillCost(null);
		} else {
			setAverageMonthlyKwh(null);
		}
	};

	useEffect(() => {
		if (averageBillCost !== null) {
			const priceCapThreshold =
				(ENV_VARS.PRICE_PER_KWH_GOV_CAP - 1) * ENV_VARS.MIN_PRICE_PER_KWH;
			const cappedPricePerKwh =
				averageBillCost < priceCapThreshold
					? ENV_VARS.MIN_PRICE_PER_KWH
					: ENV_VARS.PRICE_PER_KWH;
			updateUserAvgMonthlyConsumptionInKwh(averageBillCost / cappedPricePerKwh);
		} else if (averageMonthlyKwh !== null) {
			updateUserAvgMonthlyConsumptionInKwh(averageMonthlyKwh);
		} else {
			updateUserAvgMonthlyConsumptionInKwh(null);
		}
	}, [averageBillCost, averageMonthlyKwh]);

	return (
		<div className={`${estimators.estimatorStepOne} col-lg-12 col-12`}>
			<div className='d-flex align-items-center flex-column w-100'>
				<div className='d-flex w-100 align-items-center'>
					<input
						type='number'
						className={`form-control mb-2 w-100 ${inputs.btnHighlight} ${
							averageBillCost === null && averageMonthlyKwh === null
								? inputs.pulseBorderInput
								: averageMonthlyKwh !== null
								? inputs.borderUnimportant
								: ''
						}`}
						value={averageBillCost ?? ''}
						name='averageBillCost'
						required
						min={1}
						minLength={1}
						maxLength={6}
						max={999999}
						placeholder={intl.formatMessage({
							id: 'component.estimator.average_bill_cost_label',
							defaultMessage: 'Insert average electricity bill cost',
						})}
						onChange={(e) => handleAverageBillCost(e)}
					/>
				</div>
				<div className='text-muted fs-6 align-self-sm-start align-self-center fw-bold'>
					<span>
						<FormattedMessage id='component.estimator.average_bill_cost' />
					</span>
					<span> </span>
					<span className='text-lowercase'>
						<FormattedMessage id='component.estimator.in' defaultMessage='In' />
					</span>
					<span> </span>
					<span className={`${inputs.inputUnit}`}>RON</span>
				</div>
			</div>
			<span className='text-uppercase pt-2 pb-2 ms-sm-4 me-sm-4 fs-4 fw-bold'>
				<FormattedMessage id='component.estimator.or' defaultMessage='Or' />
			</span>
			<div className='d-flex align-items-center flex-column w-100'>
				<div className='d-flex w-100 align-items-center'>
					<input
						type='number'
						className={`form-control w-100 ${inputs.btnHighlight} ${
							averageBillCost === null && averageMonthlyKwh === null
								? inputs.pulseBorderInput
								: averageBillCost !== null
								? inputs.borderUnimportant
								: ''
						}`}
						value={averageMonthlyKwh ?? ''}
						name='averageMonthlyKwh'
						required
						max={999999}
						minLength={1}
						maxLength={6}
						min={1}
						placeholder={intl.formatMessage({
							id: 'component.estimator.average_monthly_consumption_label',
							defaultMessage: 'Insert average monthly consumption',
						})}
						onChange={(e) => handleAverageMonthlyKwhChange(e)}
					/>
				</div>
				<div className='text-muted fs-6 align-self-sm-start align-self-center fw-bold'>
					<span>
						<FormattedMessage id='component.estimator.average_monthly_consumption' />
					</span>
					<span> </span>
					<span className='text-lowercase'>
						<FormattedMessage id='component.estimator.in' defaultMessage='In' />
					</span>
					<span> </span>
					<span className={`${inputs.inputUnit}`}>kWh</span>
				</div>
			</div>
		</div>
	);
}
