import Hashids from 'hashids';
import logger from './logging';
import { prepareStringForVisualIdDecode } from './stringHelpers';

export const roundWithPrecision = (
	value: number,
	step: number,
	ceil?: boolean
) => {
	step || (step = 1.0);
	var inv = 1.0 / step;
	if (ceil === true) {
		return Math.ceil(value * inv) / inv;
	}
	return Math.round(value * inv) / inv;
};

export const safeNumber = (
	value: number,
	lowerBound?: number | undefined,
	upperBound?: number | undefined
) => {
	if (isNaN(value)) return 0;
	if (upperBound !== undefined) {
		if (value > upperBound) {
			return upperBound;
		}
	}
	if (lowerBound !== undefined) {
		if (value < lowerBound) {
			return lowerBound;
		}
	}
	return value;
};

//const hashids = new Hashids('', 6, 'abcdef0123456789');
const hashids = new Hashids(
	'',
	6,
	'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'
);

export const encodeVisualId = (id: number | undefined) => {
	if (id == undefined) {
		logger.error('Error encoding id - undefined');
		return '';
	}
	return hashids.encode(id);
};

export const decodeVisualId = (id: string | undefined) => {
	if (id == undefined) {
		logger.error('Error decoding id - undefined');
		return -1;
	}
	id = prepareStringForVisualIdDecode(id);
	return +hashids.decode(id);
};

export const generateUniqueTimestamp = () => {
	const timestamp =
		Math.round(Date.now() / 1) +
		Math.round(Math.random() * 10) * 1 +
		Math.round(Math.random() * 10) * 2;
	return timestamp;
};
